<template>
  <div class="tooltip" :class="[type, posX]">
    <button class="tooltip__button"></button>
    <div class="tooltip__body" :style="{ width: width }" v-html="msg"></div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    posX: {
      type: String,
      default: "left",
    },
    posY: {
      type: String,
      default: "bottom",
    },
    width: {
      type: String,
      default: "auto",
    },
    msg: String,
    type: String,
  },
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-flex;
  z-index: 1100;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 1.4;
  font-weight: normal;
  &_style_dark {
    .tooltip__body {
      background: rgba(1, 67, 66, 0.94);
      &::before {
        border-color: transparent transparent rgba(1, 67, 66, 0.94) transparent;
      }
    }
    td {
      padding: 4px 0 !important;
      background: none !important;
      text-align: left !important;
      color: #fff;
      &:first-child {
        padding-right: 30px !important;
      }
    }
  }
  &:hover {
    z-index: 1200;
  }
  &.fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    .tooltip__button {
      width: 100%;
      height: 100%;
      background: none;
    }
  }
  &.right {
    .tooltip__body {
      right: auto;
      left: -38px;
      &::before {
        left: 0;
        content: "";
        margin-left: 40px;
      }
    }
  }
  &.profile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: 0;
    .tooltip__button {
      width: 100%;
      height: 100%;
      background: none;
    }
    .tooltip__body {
      right: auto;
      left: 0;
      &::before {
        left: 0;
        content: "";
        margin-left: 40px;
      }
    }
  }
  &.rating {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    margin: 0;
    padding-bottom: 0;
    .tooltip__button {
      width: 100%;
      height: 100%;
      background: none;
    }
    .tooltip__body {
      right: auto;
      left: 0;
      &::before {
        left: 0;
        content: "";
        margin-left: 15px;
      }
    }
  }
  &:hover {
    .tooltip__body {
      display: block;
    }
  }
  &__button {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 21C16.2921 21 21 16.2921 21 10.5 21 4.708 16.2921 0 10.5 0 4.708 0 0 4.708 0 10.5 0 16.2921 4.708 21 10.5 21Zm0-1.1667c-5.1616 0-9.3333-4.1717-9.3333-9.3333 0-5.1616 4.1717-9.3333 9.3333-9.3333 5.1616 0 9.3333 4.1717 9.3333 9.3333 0 5.1616-4.1717 9.3333-9.3333 9.3333Zm.0638-3.4909a.583.583 0 0 0 .5195-.5924V7.5833a.5837.5837 0 0 0-.3581-.5464.584.584 0 0 0-.4504 0 .5825.5825 0 0 0-.3161.3207.5832.5832 0 0 0-.042.2257V15.75a.5836.5836 0 0 0 .402.5671.5834.5834 0 0 0 .2451.0253ZM10.5 6.125a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z' fill='%235EFFFF'/%3E%3C/svg%3E");
    width: 21px;
    height: 21px;
  }
  &__body {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(0, 98, 97, 0.95);
    font-size: 14px;
    min-width: 255px;
    padding: 20px;
    font-family: $font;
    margin-top: 18px;
    margin-right: -5px;
    display: none;
    word-break: break-word;
    &::before {
      position: absolute;
      bottom: 100%;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 8px 7px;
      border-color: transparent transparent rgba(0, 98, 97, 0.95) transparent;
      content: "";
      margin-right: 9px;
    }
    p {
      margin: 0;
      + p {
        margin-top: 10px;
      }
    }
    b {
      font-weight: normal;
      color: #5cffed;
    }
    table {
      border-spacing: 0px;
      tr {
        td {
          font-size: 14px;
          vertical-align: top;
          white-space: nowrap;
          padding: 4px 0;
          &:first-child {
            color: #50ffeb;
            text-transform: uppercase;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
