<template>
  <CommonLayout v-if="getBattleStatus === 'success'">
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__group">
            <div class="cabinet__heading">
              <h1 class="title">Поединки</h1>
              <Tooltip
                class="cabinet__heading-tooltip"
                width="350px"
                posX="right"
                :msg="`Поединки - битва со случайным соперником. <br />Награду победителя получает тот, чей результат окажется лучше. Побежденному - утешительный приз и бесценный опыт. `"
              />
              <h1 class="title title_type_inset">
                <span>KPI:</span><strong>{{ getBattle.kpi }}</strong>
              </h1>
              <Tooltip
                class="cabinet__heading-tooltip"
                posX="right"
                :msg="`KPI поединка - <b>${getBattle.kpi}</b>.<br /> Победит тот, чей показатель лучше.`"
              />
            </div>
            <router-link
              class="button button-default catalog-order"
              :to="{ name: 'battlesHistory' }"
              ><span>История поединков</span></router-link
            >
          </div>
          <BattleTimeline />
        </div>
        <div class="cabinet__body">
          <div class="battle">
            <div v-if="getBattle.state === 1" class="battle-info">
              <div class="battle-info__inner">
                <div
                  v-if="getBattle.isParticipating"
                  class="battle-info__title"
                >
                  Ваша заявка принята! Ждите уведомления о начале.<br />Ваш KPI
                  поединка - <span>{{ getBattle.kpi }}</span>
                </div>
                <div v-else class="battle-info__title">
                  Сделайте первый шаг к победе - оставьте заявку на участие.<br />Ваш
                  KPI поединка - <span>{{ getBattle.kpi }}</span>
                </div>
                <div class="battle-info__pic">
                  <img
                    src="@/assets/img/battle/vs.png"
                    width="914"
                    height="442"
                    alt=""
                  />
                </div>
                <div class="battle-info__foot">
                  <button
                    v-if="!getBattle.isParticipating"
                    class="button button-default battle-info__button"
                    @click="onParticipate"
                  >
                    <span>Участвовать</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="getBattle.state === 2">
              <template v-if="getBattle.isParticipating">
                <div class="battle-message">
                  Поединок уже начался. Вы узнаете, кто ваш соперник только
                  после окончания. Постарайтесь показать лучший результат, чтобы
                  наверняка победить
                </div>
                <div class="battle-main">
                  <div class="battle-main-head">
                    <div class="battle-main-head__item">
                      <div class="battle-main-head__label">
                        Дата проведения:
                      </div>
                      <div class="battle-main-head__title">
                        <template
                          v-if="
                            getMonth(new Date(getBattle.startDay)) ===
                            getMonth(addDays(new Date(getBattle.startDay), 13))
                          "
                        >
                          {{ getBattle.startDay | formatDateBegin }}-{{
                            getBattle.startDay | formatDateEnd
                          }}
                        </template>
                        <template v-else>
                          {{ getBattle.startDay | formatDateBeginMonth }}-{{
                            getBattle.startDay | formatDateEnd
                          }}
                        </template>
                      </div>
                    </div>
                    <div class="battle-main-head__item">
                      <div class="battle-main-head__label">KPI поединка:</div>
                      <div class="battle-main-head__title">
                        {{ getBattle.kpi }}
                      </div>
                    </div>
                  </div>
                  <div class="battle-main-body">
                    <div class="battle-main-body__item">
                      <div class="battle-main-body__head">
                        <div
                          v-if="getProfile.photo"
                          class="battle-main-body__pic"
                        >
                          <span
                            :style="{
                              'background-image': `url(${userPhoto})`,
                            }"
                          ></span>
                        </div>
                        <div v-else class="battle-main-body__pic default">
                          <span></span>
                        </div>
                        <div class="battle-main-body__name">
                          {{ getProfile.fullName }}
                        </div>
                      </div>
                      <div class="battle-main-body__content">
                        Результаты будут доступны после завершения поединка на
                        странице “История поединков”
                      </div>
                    </div>
                    <div class="battle-main-body__item">
                      <div class="battle-main-body__head">
                        <div class="battle-main-body__pic default">
                          <span></span>
                        </div>
                        <div class="battle-main-body__name">Соперник</div>
                      </div>
                      <div class="battle-main-body__content">
                        Результаты будут доступны после завершения поединка на
                        странице “История поединков”
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="battle-info">
                <div class="battle-info__inner">
                  <div class="battle-info__title">
                    К сожалению, вы не подали заявку на участие и не можете
                    участвовать в этом поединке. Нажмите “Участвовать” в
                    следующий раз до окончания периода набора участников
                  </div>
                  <div class="battle-info__pic">
                    <img
                      src="@/assets/img/battle/defender.png"
                      width="241"
                      height="371"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
  <ContentLayout v-else :is-loading="getBattleStatus === 'loading'">
    <p>
      <strong>Раздел "Поединки"</strong>
    </p>
    <p>
      Здесь вы можете соревноваться с коллегами в выполнении KPI. <br />И
      получать дополнительные награды, в момент проведения поединка.
    </p>
    <router-link
      class="button button-default catalog-order"
      :to="{ name: 'battlesHistory' }"
      ><span>История поединков</span></router-link
    >
  </ContentLayout>
</template>

<script>
import { BattlesParticipate } from "@/api/Battles";
import BattleTimeline from "@/components/BattleTimeline.vue";
import Tooltip from "@/components/Tooltip.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import ContentLayout from "@/layout/ContentLayout.vue";
import { getBaseURL } from "@/utils";
import { addDays, format, getMonth } from "date-fns";
import { ru } from "date-fns/locale";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CommonLayout,
    ContentLayout,
    BattleTimeline,
    Tooltip,
  },
  data() {
    return {
      ideas: [],
    };
  },
  created() {
    this.BATTLE_FETCH();
  },
  filters: {
    formatDateBegin(date) {
      if (!date) return "";
      return format(new Date(date), "d", {
        locale: ru,
      });
    },
    formatDateBeginMonth(date) {
      if (!date) return "";
      return format(new Date(date), "d MMMM", {
        locale: ru,
      });
    },
    formatDateEnd(date) {
      if (!date) return "";
      return format(addDays(new Date(date), 13), "d MMMM yyyy", {
        locale: ru,
      });
    },
  },
  computed: {
    ...mapGetters(["getBattle", "getBattleStatus", "getProfile"]),
    userPhoto() {
      return getBaseURL() + this.getProfile.photo;
    },
  },
  methods: {
    ...mapActions(["BATTLE_FETCH", "BATTLE_UPDATE"]),
    addDays,
    getMonth,
    async onParticipate() {
      try {
        await BattlesParticipate();
        this.$modal.show("messageLarge", {
          message:
            "Поздравляем, ваша заявка принята! Ждите уведомления о начале.",
        });
        setTimeout(() => {
          this.BATTLE_UPDATE();
        }, 500);
      } catch (error) {
        const { data } = error;

        this.$modal.show("messageLarge", {
          message: data ? data : "Упс, что-то пошло не так",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/styles/battle.scss"></style>
