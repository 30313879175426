<template>
  <CommonLayout class="page_fullscreen">
    <Loading v-if="isLoading" position="center" style="width: 100%" />
    <div v-else class="content">
      <slot />
      <button
        v-if="hasButtonBack"
        type="button"
        class="button button-default button_big"
        @click="$router.go(-1)"
      >
        Вернуться назад
      </button>
    </div>
  </CommonLayout>
</template>

<script>
import Loading from "@/components/Loading.vue";
import CommonLayout from "@/layout/CommonLayout.vue";

export default {
  props: {
    hasButtonBack: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonLayout,
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  &::v-deep {
    h2 {
      font-weight: normal;
      font-size: 400px;
      line-height: 1;
      color: #5cffed;
      margin: -100px 0 0 0;
    }
    h1 {
      font-weight: normal;
      font-size: 70px;
      line-height: 1;
      color: #009382;
      margin: 0;
    }
    p {
      font-size: 20px;
      line-height: 23px;
    }
    .button {
      margin-top: 15px;
    }
  }
}
</style>
